import React from "react"
import { Transition } from "@headlessui/react"
import { useState } from "react"

const MyComponent = () => {
  const [isShowing, setIsShowing] = useState(true)

  return (
    <>
      <button onClick={() => setIsShowing(isShowing => !isShowing)}>
        Toggle
      </button>
      <Transition
        show={isShowing}
        enter="transition-opacity duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="p-5 bg-green-300 shadow">I will fade in and out</div>
      </Transition>
    </>
  )
}

export default MyComponent
